<template>
  <div>
    <v-header></v-header>
    <div class="publish">
      <van-tabs v-model="currTab" swipeable animated :lazy-render="false">
        <van-tab title="收藏的需求" name="needs">
          <div class="publish-tab-item">
            <van-pull-refresh v-model="needs.refreshing" @refresh="loadNeeds(1,true,200)">
              <van-list v-show="needs.total>0"
                        offset="50"
                        :immediate-check="true"
                        :finished="!needs.hasMore"
                        finished-text="灵感到底了~"
                        v-model="needs.loading"
                        @load="loadNeeds(needs.current+1,false)">
                <div class="needs-item" v-for="item in needs.records" :key="item.id">
                  <needs-card :item="item" show-collect @updateStatus="(status)=>item.status=status"/>
                </div>
              </van-list>
              <div class="scroll-empty" v-show=" currTab === 'needs' && needs.total<=0">
                <img src="https://cdn.001ppt.cn/h5/assets/images/noData.png" width="150" style="margin-bottom: 20px">
                <div>噢！这可能有点儿空 (⊙v⊙)</div>
              </div>
            </van-pull-refresh>
          </div>
        </van-tab>

        <van-tab title="收藏的资源" name="bizResource">
          <div class="publish-tab-item">
            <van-pull-refresh v-model="resource.refreshing" @refresh="loadResource(1,true,200)">
              <van-list v-show="resource.total>0"
                        offset="50"
                        :immediate-check="true"
                        :finished="!resource.hasMore"
                        finished-text="灵感到底了~"
                        v-model="resource.loading"
                        @load="loadNeeds(resource.current+1,false)">
                <div class="needs-item" v-for="item in resource.records" :key="item.id">
                  <biz-resource-card :item="item" show-collect @updateStatus="(status)=>item.status=status"/>
                </div>
              </van-list>
              <div class="scroll-empty" v-show="currTab === 'bizResource' && resource.total<=0">
                <img src="https://cdn.001ppt.cn/h5/assets/images/noData.png" width="150" style="margin-bottom: 20px">
                <div>噢！这可能有点儿空 (⊙v⊙)</div>
              </div>
            </van-pull-refresh>
          </div>
        </van-tab>
      </van-tabs>


    </div>
  </div>
</template>


-------------------- script --------------------

<script>
import NeedsCard from '@/components/biz/NeedsCard';
import BizResourceCard from '@/components/biz/BizResourceCard';
import {needsV2Api} from "@/api/v2/needsV2Api";
import {sleep} from "@/config/util";
import {bizResourceV2Api} from "@/api/v2/bizResourceV2Api";

export default {
  components: {NeedsCard, BizResourceCard},
  data() {
    return {
      currTab: '',
      needs: {
        current: 1,
        size: 10,
        total: 0,
        records: [],
        hasMore: false,
        refreshing: false,
        loading: false
      },
      resource: {
        current: 1,
        size: 10,
        total: 0,
        records: [],
        hasMore: false,
        refreshing: false,
        loading: false
      }
    }
  },


  async created() {
    this.loadNeeds(1, true, 200);
    this.loadResource(1, true, 200);
  },


  methods: {

    // 加载数据
    async loadNeeds(current, clearRecords = false, minLoadingMs) {
      try {
        await sleep(minLoadingMs || 0);
        const pageSize = this.needs.size;
        const params = {
          current, pageSize,
          size: pageSize,
        };

        const res = await needsV2Api.findMyCollect(params);
        for(const r of res.records){
          r.hasCollect = true
        }
        this.needs.current = res.current;
        this.needs.pageSize = res.size;
        this.needs.total = res.total;
        if (clearRecords) {
          this.needs.records = res.records;
        } else {
          this.needs.records = [...this.needs.records, ...res.page.records];
        }
      } finally {
        this.needs.hasMore = this.needs.current * this.needs.size < this.needs.total;
        this.needs.refreshing = false;
        this.needs.loading = false
      }
    },


    // 加载数据
    async loadResource(current, clearRecords = false, minLoadingMs) {
      try {
        await sleep(minLoadingMs || 0);
        const pageSize = this.resource.size;
        const params = {
          current, pageSize,
          size: pageSize,
        };

        const res = await bizResourceV2Api.findMyCollect(params);
        for(const r of res.records){
          r.hasCollect = true
        }
        this.resource.current = res.current;
        this.resource.pageSize = res.size;
        this.resource.total = res.total;
        if (clearRecords) {
          this.resource.records = res.records;
        } else {
          this.resource.records = [...this.resource.records, ...res.page.records];
        }
      } finally {
        this.resource.hasMore = this.resource.current * this.resource.size < this.resource.total;
        this.resource.refreshing = false;
        this.resource.loading = false
      }
    }

  }

}
</script>


-------------------- style --------------------

<style scoped lang="less">
.publish {
  margin-top: var(--header-height);
  background: #f7f7f7;
}

.publish-tab-item {
  height: calc(100vh - var(--header-height) - 50px);
  overflow-y: scroll;
}


.scroll-empty {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: var(--grey-color);
}
</style>
